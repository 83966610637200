import { getLaunchDarklyClient } from './launch-darkly-client';

// Types
import type { Plugin } from '@nuxt/types';

/**
 * Launch darkly plugin
 *
 * @param _
 * @param inject
 * @returns {Promise<void>}
 */
export const launchDarkly: Plugin = async (_, inject): Promise<void> => {
  const client = getLaunchDarklyClient();

  if (client === undefined) {
    inject('launchDarkly', {
      variation() { },
    });
    return;
  }

  await client.waitUntilReady();
  inject('launchDarkly', client);
};

export default launchDarkly;
