var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('label',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeDropdown),expression:"closeDropdown"}],class:[
    'input-container',
    { 'input-container--error' : _vm.error && _vm.error.$error },
    'dropdown',
  ]},[_c('span',{staticClass:"label-name",class:{ 'label-name--star': _vm.addRequiredStar }},[_vm._v("\n    "+_vm._s(_vm.label)+"\n  ")]),_vm._v(" "),_c('div',{staticClass:"input-container__wrapper"},[_c('div',{staticClass:"input-container__inner-wrapper"},[_c('input',{class:[
          'input-control',
          `input-control--text`,
          { 'error': _vm.errorExists },
        ],attrs:{"type":_vm.inputType,"inputmode":_vm.inputMode,"placeholder":_vm.placeholder},domProps:{"value":_vm.value},on:{"click":_vm.onClick,"input":_vm.onInput,"focus":_vm.onFocus,"blur":_vm.onBlur}}),_vm._v(" "),_c('div',{staticClass:"input-container__append-wrapper"},[(_vm.items.length)?_c('IconArrowDown',{staticClass:"input-container__append-icon__arrow",attrs:{"tabindex":"0"},nativeOn:{"click":function($event){$event.preventDefault();_vm.open = !_vm.open}}}):_vm._e()],1)]),_vm._v(" "),(_vm.open)?_c('div',{staticClass:"dropdown-list",attrs:{"data-testid":"text-dropdown-list"}},_vm._l((_vm.items),function(item,index){return _c('button',{key:index,staticClass:"dropdown-item",attrs:{"type":"button","data-testid":"text-dropdown-item"},on:{"click":function($event){$event.stopPropagation();$event.preventDefault();return _vm.itemSelected(item)}}},[_vm._v("\n        "+_vm._s(item.value)+"\n      ")])}),0):_vm._e()]),_vm._v(" "),(_vm.errorExists)?_c('p',{staticClass:"error-msg"},[_vm._v("\n    "+_vm._s(_vm.errorMessage)+"\n  ")]):_vm._e()])
}
var staticRenderFns = []

export { render, staticRenderFns }