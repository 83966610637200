var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return _c('div',{staticClass:"booking-baggage-details container"},[_c('BackButton',{on:{"btnClick":_vm.goToBookingGuests}}),_vm._v(" "),_c('div',{staticClass:"booking-baggage-details__card"},[_c('h1',{staticClass:"booking-baggage-details__title mb-4"},[_vm._v("\n      "+_vm._s(_vm.$t(
          `bookings.baggageDetails.${
            _vm.isCheckedBaggage ? 'checkedBaggage' : 'cabinBaggage'
          }`
        ))+"\n    ")]),_vm._v(" "),_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.submit.apply(null, arguments)}}},[(_vm.isCheckedBaggage)?_c('div',{staticClass:"booking-baggage-details__input-container mb-6"},[_c('p',{staticClass:"label-name label-name--star"},[_vm._v("\n          "+_vm._s(_vm.$t('bookings.baggageDetails.captions.checkedBags'))+"\n        ")]),_vm._v(" "),_c('DropdownWrapper',{attrs:{"required":"","dropdown-opened":_vm.bagsOpened,"label":_vm.$t('bookings.baggageDetails.checkedBagsPlaceholder'),"error-html":_vm.$t('bookings.baggageDetails.validationErrors.bags'),"error":_vm.$v.baggage.baggage_count.$error,"items":_vm.bagsSelections},on:{"update:dropdownOpened":function($event){_vm.bagsOpened=$event},"update:dropdown-opened":function($event){_vm.bagsOpened=$event}},model:{value:(_vm.baggage.baggage_count),callback:function ($$v) {_vm.$set(_vm.baggage, "baggage_count", $$v)},expression:"baggage.baggage_count"}})],1):_vm._e(),_vm._v(" "),(_vm.isCheckedBaggage)?_c('div',{staticClass:"booking-baggage-details__content mb-6"},[_c('h2',{staticClass:"sub-heading label-name--star mb-4",class:{
            'error-msg-text': _vm.$v.baggage.has_oversized_baggage.$error,
          }},[_vm._v("\n          "+_vm._s(_vm.$t('bookings.baggageDetails.specialBaggage.title'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"info mb-4"},[_c('div',{staticClass:"info-icon"},[_c('IconAlert')],1),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('bookings.baggageDetails.explanations.oversize'))+"\n          ")])]),_vm._v(" "),_c('RadioButton',{staticClass:"mb-2",attrs:{"name":"has_oversized_baggage","option":true,"label":_vm.specialBaggageOptions.true},model:{value:(_vm.baggage.has_oversized_baggage),callback:function ($$v) {_vm.$set(_vm.baggage, "has_oversized_baggage", $$v)},expression:"baggage.has_oversized_baggage"}}),_vm._v(" "),_c('RadioButton',{attrs:{"name":"has_oversized_baggage","option":false,"label":_vm.specialBaggageOptions.false},model:{value:(_vm.baggage.has_oversized_baggage),callback:function ($$v) {_vm.$set(_vm.baggage, "has_oversized_baggage", $$v)},expression:"baggage.has_oversized_baggage"}})],1):_vm._e(),_vm._v(" "),_c('div',{staticClass:"booking-baggage-details__content mb-6"},[_c('h2',{staticClass:"sub-heading mb-4 label-name--star",class:{
            'error-msg-text': _vm.$v.baggage.baggage_declaration_agreement.$error,
          }},[_vm._v("\n          "+_vm._s(_vm.$t('bookings.baggageDetails.bagDeclaration'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"info mb-4"},[_c('div',{staticClass:"info-icon"},[_c('IconAlert')],1),_vm._v(" "),(_vm.isCheckedBaggage)?_c('div',{staticClass:"d-flex"},[_c('p',{staticClass:"mb-4"},[_vm._v("\n              "+_vm._s(_vm.$t(
                  'bookings.baggageDetails.bagDeclarationInstructions.line_one'
                ))+"\n            ")]),_vm._v(" "),_c('p',{staticClass:"mb-4"},[_vm._v("\n              "+_vm._s(_vm.$t(
                  'bookings.baggageDetails.bagDeclarationInstructions.line_two'
                ))+"\n            ")]),_vm._v(" "),_c('p',[_vm._v("\n              "+_vm._s(_vm.$t(
                  'bookings.baggageDetails.bagDeclarationInstructions.line_three'
                ))+"\n            ")])]):_c('p',[_vm._v("\n            "+_vm._s(_vm.$t(
                'bookings.baggageDetails.bagDeclarationInstructions.cabin_baggage'
              ))+"\n          ")])]),_vm._v(" "),_c('div',{staticClass:"input-title ml-2"},[_c('Checkbox',{staticClass:"input-title__checkbox",attrs:{"required":"","error":_vm.$v.baggage.baggage_declaration_agreement.$error,"error-html":_vm.$t('bookings.baggageDetails.validationErrors.bags'),"name":"baggage_declaration_agreement","color":"brand-parkings","width":"14","height":"14"},model:{value:(_vm.baggage.baggage_declaration_agreement),callback:function ($$v) {_vm.$set(_vm.baggage, "baggage_declaration_agreement", $$v)},expression:"baggage.baggage_declaration_agreement"}}),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('bookings.baggageDetails.understand'))+"\n          ")])],1)]),_vm._v(" "),_c('div',{staticClass:"booking-baggage-details__content mb-4"},[_c('h2',{staticClass:"sub-heading label-name--star mb-4",class:{
            'error-msg-text': _vm.$v.baggage.custom_declaration.$error,
          }},[_vm._v("\n          "+_vm._s(_vm.$t('bookings.baggageDetails.customDeclaration.title'))+"\n        ")]),_vm._v(" "),_c('div',{staticClass:"info mb-4"},[_c('div',{staticClass:"info-icon"},[_c('IconAlert')],1),_vm._v(" "),_c('p',[_vm._v("\n            "+_vm._s(_vm.$t('bookings.baggageDetails.explanations.declaration'))+"\n            "),_c('a',{attrs:{"href":"https://www.gov.uk/check-customs-declaration","target":"_blank","rel":"noopener"}},[_vm._v("Find out more")])])]),_vm._v(" "),_c('RadioButton',{staticClass:"mb-2",attrs:{"name":"custom_declaration","option":false,"label":_vm.customDeclarationOptions.false},model:{value:(_vm.baggage.custom_declaration),callback:function ($$v) {_vm.$set(_vm.baggage, "custom_declaration", $$v)},expression:"baggage.custom_declaration"}}),_vm._v(" "),_c('RadioButton',{attrs:{"name":"custom_declaration","option":true,"label":_vm.customDeclarationOptions.true},model:{value:(_vm.baggage.custom_declaration),callback:function ($$v) {_vm.$set(_vm.baggage, "custom_declaration", $$v)},expression:"baggage.custom_declaration"}})],1),_vm._v(" "),_c('button',{staticClass:"booking-baggage-details__confirm-button mt-6 button button-primary",attrs:{"id":"confirm"}},[_vm._v("\n        "+_vm._s(_vm.$t('bookings.baggageDetails.confirm'))+"\n      ")])])])],1)
}
var staticRenderFns = []

export { render, staticRenderFns }