
import { defineComponent, PropType } from 'vue';
import { formatPrice } from '@white-label-helper/helper-payment';
import { format, DATE_TIME_FORMATS } from '@white-label-helper/date-utilities';
import {
  dispatchRemoveFromCart,
  ProductsType,
  commitClearCart
} from '@white-label-store/cart';
import { datadogRum } from '@datadog/browser-rum';
import { StatusCodes } from 'http-status-codes';
import ProductSummaryCardIcon from './product-summary-card-icon.vue';
import ProductSummaryCardDropdown from './product-summary-card-dropdown.vue';
import InfoGuests from './sub-components/guests-info.vue';
import InfoFlightTime from './sub-components/flight-time-info.vue';
import InfoPrivateLounge from './sub-components/private-lounge-info.vue';
import ProductTags from '../product-tags/product-tags.vue';
import { TranslateResult } from 'vue-i18n';
import type { GenerateProduct } from '@white-label-types/parking-booking';
import { NAMED_ROUTES } from '@white-label-configuration/constants';
import type { Fee } from '@white-label-types/parking-checkout';
import { getAppVariable } from '@white-label-helper/get-app-variable';

export default defineComponent({
  name: 'ProductSummaryCard',
  components: {
    IconArrow: () => import('@white-label-icon/icon-arrow'),
    IconShield: () => import('@white-label-icon/icon-shield'),
    ProductSummaryCardIcon,
    ProductSummaryCardDropdown,
    InfoGuests,
    ProductTags,
    InfoFlightTime,
    PrivateLoungeInfo: InfoPrivateLounge
  },
  props: {
    displayLocation: {
      type: String,
      default: 'basket'
    },
    showIcon: {
      type: Boolean,
      default: false
    },
    product: {
      type: Object as PropType<GenerateProduct>,
      required: true
    },
    hasRemoveButton: {
      type: Boolean,
      default: true
    },
    displayTravelExtrasSummary: {
      type: Boolean,
      default: false
    },
    amendingTitle: {
      type: Boolean,
      default: false
    }
  },
  data() {
    return {
      isOpen: false,
      isDisabled: false
    };
  },
  computed: {
    componentList(): { header: string[]; info: string[] } {
      const components: { header: string[]; info: string[] } = {
        header: [],
        info: []
      };
      if (['lounges', 'fasttrack'].includes(this.productType)) {
        components.header.push('ProductTags');
          components.info.push('InfoGuests');
          if (this.isPrivateLounge) {
            components.info.push('PrivateLoungeInfo');
            components.info.unshift('InfoFlightTime');
          }
      }
      return components;
    },
    entryText(): TranslateResult {
      if (!this.entryExitVisible().entry) {
        return '';
      }

      const entryDate = format(
        new Date(this.product?.inventory_item?.entry_date),
        DATE_TIME_FORMATS.weekday_month_day_year_am_pm_lower_case
      );
      let exitDate = '';

      if (this.productType === 'lounges' || this.productType === 'fasttrack' ) {
        exitDate = format(
          new Date(this.product?.inventory_item?.exit_date),
          DATE_TIME_FORMATS.time_lower_case
        );
      }
      return this.$t(`UI.productSummaryCard.info.entry.${this.productType}`, {
        entryDate,
        exitDate
      });
    },
    exitText(): TranslateResult {
      if (!this.entryExitVisible().exit) {
        return '';
      }

      const exitDate = format(
        new Date(this.product?.inventory_item?.exit_date),
        DATE_TIME_FORMATS.weekday_month_day_year_am_pm_lower_case
      );

      return this.$t(`UI.productSummaryCard.info.exit.${this.productType}`, {
        exitDate
      });
    },
    iconName(): string {
      if (this.productType === 'lounges') {
        if (this.isPrivateLounge) {
          return this.privateLoungeFlyingDirection === 'inbound'
            ? 'plane-end'
            : 'plane-takeoff';
        }

        return 'lounges';
      }

      if (this.productType === 'fasttrack') {
        return this.productOption === 'security' ? 'fasttrack' : 'invoice';
      }

      return 'parking';
    },
    productType(): string {
      return this.product?.product_code ?? 'parking';
    },
    isPrivateLounge() {
      return this.productOption === 'lounge_private';
    },
    productOption() {
      return this.product.inventory_item?.product_option?.code;
    },
    privateLoungeFlyingDirection() {
      return (
        this.product.inventory_item?.direction ?? 'inbound'
      ).toLowerCase();
    },
    fees(): Fee[] {
      return !getAppVariable('is_fee_inclusive')
        ? this.product.fees
        : [];
    },
    hasCancellationProtection() {
      return this.product.cancellation_policies?.is_protected || this.product.is_protected;
    }
  },

  methods: {
    formatPrice,
    entryExitVisible() {
      const visibility = {
        entry: true,
        exit: true
      };

      if (this.productType === 'lounges' || this.productType === 'fasttrack') {
        visibility.exit = false;
        if (this.isPrivateLounge) {
          visibility.entry = false;
        }
      }

      if (this.productType === 'cancellation_protection') {
        visibility.entry = false;
        visibility.exit = false;
      }

      return visibility;
    },
    async removeItem(item: ProductsType) {
      this.isDisabled = true;
      await dispatchRemoveFromCart(this.$store, item.id)
        .then(() => {
          this.$store.commit('searchResults/removeCartItemId', {
            cart_item_id: item.id
          });
        })
        .catch((error) => {
          this.$store.commit('multiBasket/storeShowBasketSlideOver', false);

          const statusCode = JSON.parse(error.message)?.status;
          const unableToRemoveStatusCodes = [
            StatusCodes.INTERNAL_SERVER_ERROR,
            StatusCodes.NOT_FOUND
          ];
          const sessionExpiredStatusCodes = [StatusCodes.GONE];
          datadogRum.addError(error);

          if (unableToRemoveStatusCodes.includes(statusCode)) {
            this.$openModal('GlobalModalError', {
              header: this.$t('UI.modalError.unableToRemoveProductHeader'),
              body: this.$t('UI.modalError.unableToRemoveProductBody'),
              btnText: this.$t('shared.buttons.close')
            });
          }

          if (sessionExpiredStatusCodes.includes(statusCode)) {
            this.$openModal('GlobalModalError', {
              header: this.$t('UI.modalError.sessionExpiredHeader'),
              body: this.$t('UI.modalError.sessionExpiredError'),
              btnText: this.$t('UI.modalError.startNewSearch')
            }).then(() => {
              this.basketExpiredErrorHandler();
            });
          }
        });
    },
    basketExpiredErrorHandler() {
      // Reset cart
      commitClearCart(this.$store);
      localStorage.setItem('initToken', '');
      localStorage.setItem('vuex', JSON.stringify({ cart: { totals: null } }));
      this.$router.push(NAMED_ROUTES.home);
    }
  }
});
