var render = function render(){var _vm=this,_c=_vm._self._c,_setup=_vm._self._setupProxy;return (_vm.isConfirmed || _vm.isInProgress)?_c('div',{directives:[{name:"click-outside",rawName:"v-click-outside",value:(_vm.closeModal),expression:"closeModal"}],staticClass:"mb-navigation",class:{ 'nav-messages': !_vm.orderIsCancellable || _vm.isInProgress || _vm.isLessOneHour }},[(_vm.isMobileResolution)?_c('ControlButton',{staticClass:"w-full",attrs:{"type-of-element":"button","button-type":"primary"},on:{"btnClick":_vm.toggleVisibleNavigationMenu},nativeOn:{"click":function($event){$event.stopPropagation();return _vm.toggleVisibleNavigationMenu.apply(null, arguments)}}},[_vm._v("\n    "+_vm._s(_vm.$t('manageBooking.menu.myBooking'))+"\n    "),_c('IconMenuRight',{staticClass:"ml-2",attrs:{"width":20,"height":20}})],1):_vm._e(),_vm._v(" "),_c('ul',{staticClass:"mb-navigation__wrap",class:{ 'visible-menu': _vm.isMobileMenuVisible }},[_vm._l((_vm.menuItems),function(item){return [(item.isActive)?_c('MBNavigationItem',{key:item.name,attrs:{"path":item.path,"button-ga-id":item.id,"icon-name":item.icon},on:{"click":function($event){return _vm.navigationAction(item)}}},[_vm._v("\n        "+_vm._s(_vm.$t(item.name))+"\n      ")]):_vm._e()]}),_vm._v(" "),(_vm.isCrossSellAndMultiItem && !_vm.isCancelled)?_c('div',{staticClass:"pr-8",class:`${
        `${_vm.stylesForShareButton()}  mb-navigation-share-button`
      }`},[_c('ManageBookingShareButton',{attrs:{"order-data":_vm.orderData,"should-align-left":true}})],1):_vm._e(),_vm._v(" "),(
        (_vm.isLessOneHour || _vm.isInProgress || !_vm.isAmendable) && !_vm.isCrossSellAndMultiItem
      )?_c('IconMessage',{staticClass:"mb-navigation__message",class:{
        'mb-navigation__message--unchangeable': _vm.isLessOneHour || _vm.isInProgress,
        'mb-navigation__message--non-amendable': !_vm.isAmendable,
      },attrs:{"icon-type":"warning"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('IconWarning')]},proxy:true}],null,false,2043685186)},[_vm._v(" "),(_vm.isLessOneHour)?[_vm._v("\n        "+_vm._s(_vm.$t('manageBooking.unchangeableBooking'))+"\n      ")]:(_vm.isInProgress)?[_vm._v("\n        "+_vm._s(_vm.$t('manageBooking.youCannotModifyBooking'))+"\n      ")]:(!_vm.isAmendable)?[_vm._v("\n        "+_vm._s(_vm.$t('manageBooking.nonAmendableBooking'))+"\n      ")]:_vm._e()],2):_vm._e(),_vm._v(" "),(!_vm.orderIsCancellable)?_c('IconMessage',{staticClass:"mb-navigation__message mb-navigation__message--non-cancellable",attrs:{"data-testid":"mb-navigation--non-cancellable-booking","icon-type":"info"},scopedSlots:_vm._u([{key:"icon",fn:function(){return [_c('IconAlert')]},proxy:true}],null,false,2044860582)},[_vm._v("\n      "+_vm._s(_vm.$t('manageBooking.nonCancellableBooking'))+"\n    ")]):_vm._e()],2)],1):_vm._e()
}
var staticRenderFns = []

export { render, staticRenderFns }